<template>
  <div id="courseList">
    <div class="cm-container">
      <div class="teacher">
        <div style="width:65%;height:100px;margin:0 auto;padding:30px;position:relative;">
          <div style="color:#fff;position:absolute;top:20%;left:3.6%;cursor:pointer;" >
            <el-upload
            class="avatar-uploader"
            :action="baseUrl+'/Training_API/api/UploadFile/MultiFileUpload?fileType=1'"
            :show-file-list="false"
            :headers="headers"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
          <el-avatar style="text-align: center;margin-top: 10px;float:left;border:3px solid #76C8F6;"
             :size=100  :src="imageUrl == null&&userInfo.sex==1 ? require('@/assets/img/icon/boy.png'): imageUrl == null&&userInfo.sex==2 ? require('@/assets/img/icon/girl.png'): imageUrl"></el-avatar>
          </el-upload>
          <p style="text-align: center;">更换头像</p>
          </div>
          <div style="margin-left:130px;color:#fff">
            <p style="font-weight:600;">{{ userInfo.realName }}</p>
            <p style="font-size:14px;padding-top:15px">
              <span style="display:inline-block;width:200px">
                性别：{{ userInfo.sex == 1 ? '男' : '女' }}
              </span>
              <span style="padding-left:50px;display:inline-block;width:200px">
                学校：{{ userInfo.schoolName }}
              </span>
            </p>
            <p style="font-size:14px;padding-top:15px">
              <span style="display:inline-block;width:200px">
                QQ：{{ userInfo.QQ }}
              </span>
              <span style="padding-left:50px;display:inline-block;width:200px">
                院系：{{ userInfo.departmentName }}
              </span>
            </p>
            <p style="font-size:14px;padding-top:15px">
              <span style="display:inline-block;width:200px">
                邮箱：{{ userInfo.email }}
              </span>
              <span style="padding-left:50px;display:inline-block;width:200px">
                课程： <span v-for="item in userInfo.subjects">{{ item.subjectName + ' '  }}</span>
              </span>
            </p>
          </div>
        </div>

      </div>
      <div class="conentTeacher">
        <div style="width:12%;height:600px;background:#fff">
          <div style="width:100%;height:10%;text-align:center;line-height:50px;" v-for="(item, index) in dataList"
            :key="index">
            <p :class="titleIndex == index ? 'titleClass' : ''" style="cursor: pointer;" @click="titleClick(index)">{{
              item.title }}</p>
          </div>
        </div>
        <div style="width:86%;" v-if="titleIndex == 0">
          <task v-if="titleIndex == 0"></task>
        </div>

        <!-- 课程管理 -->
        <div style="width:86%;" v-if="titleIndex == 1">
          <course-teacher v-if="titleIndex == 1"></course-teacher>
        </div>
        <!-- 题库管理 -->
        <div style="width:86%;height:600px;" v-if="titleIndex == 2">
          <question-template v-if="titleIndex == 2"></question-template>
        </div>
        <!-- 班级管理 -->
        <div style="width:86%;height:600px;" v-if="titleIndex == 3">
          <topic-template v-if="titleIndex == 3" @fanhui="fanhui"></topic-template>
        </div>
        <!-- 课程实训 -->
        <div style="width:86%;height:600px;" v-if="titleIndex == 4">
          <system-experience v-if="titleIndex == 4"></system-experience>
        </div>
        <!-- 系统实训 -->
        <div style="width:86%;height:600px;" v-if="titleIndex == 5">
          <digital-training v-if="titleIndex == 5"></digital-training>
        </div>
        <!-- 个人资料 -->
        <div style="width:86%;height:600px;" v-if="titleIndex == 6">
          <personal-data v-if="titleIndex == 6" @confirmcourse="confirmcourse"></personal-data>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { reactive, toRefs, onMounted, onUnmounted } from "vue";
// import router from "../../router/index.js";
import { F_Get_subscribeCourseForStudentId } from '@/js/teachingSquare'
import { setStorage, getStorage, Newdata } from "@/js/common";
import courseTeacher from "./courseManagementTeacher.vue"
import questionTemplate from "./questionBankTemplate.vue"
import topicTemplate from "./topicTemplate.vue"
import systemExperience from "./systemExperience.vue"
import digitalTraining from "./digitalTraining.vue"
import personalData from "./personalData.vue"
import Task from './task.vue'
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { f_get_userInfo } from "@/js/homeListApi";
import { baseUrl } from '@/js/util.js'
import { DeleteFiles,f_update_userPic} from "@/js/courseManagementApi"
import { ElMessage } from 'element-plus';
export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      currentPage4: 1,
      dialogVisible: false,
      userInfo: [],
      baseUrl:baseUrl,
      imageUrl:'',
      imgUrlId:[],
      headers: {
                token: getStorage('ftoken'),
                operate: true
            },
      titleIndex: getStorage("titleIndex"),//显示2题库
      dataList: [
        {
          title: '当前任务'
        }, {
          title: '课程管理'
        }, {
          title: '题库管理'
        }, {
          title: '班级管理'
        }, {
          title: '课程实训'
        }, {
          title: '系统实训'
        }, {
          title: '个人资料'
        }, {
          title: '帮助中心'
        },
      ],
    });

    const methods = {
      confirmcourse(params){
        console.log(params);
        state.userInfo = params
      },
       // 修改上传头像成功
       f_update_userPic(){
        let params = {
          id:getStorage('userId'),
          fileId:state.imgUrlId.toString()
        }
        f_update_userPic(params).then(res=>{
          if (res.data.code== 1) {
            ElMessage.success('修改成功')
          }else{
            ElMessage.error(res.data.message)
          }
        })
      },
      handleAvatarSuccess(res){
        console.log(res);
        if (res.code == 1) {
          state.imageUrl = state.baseUrl+'/'+res.data[0].filePath;
          state.imgUrlId = [];
          state.imgUrlId.push(res.data[0].Id);
          methods.f_update_userPic();
        }else{
          ElMessage.error(res.message)
        }
       
        
      },
      
      beforeAvatarUpload(res){
        if (res.code == 1) {
          if (state.imgUrlId.length>0) {
          DeleteFiles(state.imgUrlId).then(res => {
                    if (res.data.code == 1) {
                        state.imgUrlId = [];
                        state.imageUrl = null;
                    }
                })
        }
        }
        
        
      },
      titleClick(index) {
        console.log(index, 'index')
        state.titleIndex = index;
        setStorage("titleIndex", state.titleIndex)
        if (state.titleIndex == 7) {
          router.push('/home/helpCenter')
        }
      },
      handleClose() {
        state.dialogVisible = false;
      },
      fanhui(value) {
        console.log(value);
        state.titleIndex = value;
      },
      //获取个人信息
      userList() {
        const params = {
          userId: getStorage('userId'),
          userType: getStorage("StudentOrTeacher")
        }
        f_get_userInfo(params).then(res => {
          console.log(res.data);
          const { code, data } = res.data
          if (code == 1) {
            let reg = /^(\d{3})\d*(\d{4})$/;
            data.phoneNum = data.phoneNum.replace(reg, '$1****$2')
            data.avater = data.userPic.filePath;
            if (data.userPic.filePath) {
              state.imageUrl = state.baseUrl+'/'+data.userPic.filePath;
            }else{
              state.imageUrl = null;
            }
            
            state.userInfo = data
            // localStorage.setItem('userInfo',JSON.stringify(data))
          }
        })
      },
    };
    onMounted(() => {
      methods.userList()
    });
    onUnmounted(() => { });
    return {
      ...toRefs(state),
      ...methods,
    };
  },
  components: { courseTeacher, questionTemplate, topicTemplate, systemExperience, digitalTraining, personalData, Task },
};
</script>
<style lang="scss" scoped>
#courseList {
  height: 100%;
  position: relative;

  .cm-container {
    width: 66.5%;
    height: 100%;
    margin: 0 auto;

    .teacher {
      width: 100%;
      height: 200px;
      background-image: url('../../../assets/img/head/teacherBag.png');
      margin-top: 20px;
      ;
    }

    .conentTeacher {
      width: 100%;
      height: 600px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }

    .titleClass {
      color: #76C8F6;
    }

    .el-table {
      :deep .el-table__body-wrapper::-webkit-scrollbar {
        width: 5px;
        /*滚动条宽度*/
        height: 10px;
        /*滚动条高度*/
      }

      /*定义滚动条轨道 内阴影+圆角*/
      :deep .el-table__body-wrapper::-webkit-scrollbar-track {
        box-shadow: 0px 1px 3px #fff inset;
        /*滚动条的背景区域的内阴影*/
        border-radius: 10px;
        /*滚动条的背景区域的圆角*/
        background-color: #fff;
        /*滚动条的背景颜色*/
      }

      /*定义滑块 内阴影+圆角*/
      :deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
        box-shadow: 0px 1px 3px #76C8F6 inset;
        /*滚动条的内阴影*/
        border-radius: 10px;
        /*滚动条的圆角*/
        background-color: #76C8F6;
        /*滚动条的背景颜色*/
      }
    }

    :deep div::-webkit-scrollbar {
      width: 5px;
      /*滚动条宽度*/
      height: 10px;
      /*滚动条高度*/
    }

    /*定义滚动条轨道 内阴影+圆角*/
    :deep div::-webkit-scrollbar-track {
      box-shadow: 0px 1px 3px #fff inset;
      /*滚动条的背景区域的内阴影*/
      border-radius: 10px;
      /*滚动条的背景区域的圆角*/
      background-color: #fff;
      /*滚动条的背景颜色*/
    }

    /*定义滑块 内阴影+圆角*/
    :deep div::-webkit-scrollbar-thumb {
      box-shadow: 0px 1px 3px #76C8F6 inset;
      /*滚动条的内阴影*/
      border-radius: 10px;
      /*滚动条的圆角*/
      background-color: #76C8F6;
      /*滚动条的背景颜色*/
    }
  }

}

@media screen and (min-width: 1600px) {
  #courseList {
    .cm-container {
      width: 1400px;


    }
  }
}
</style>
