<template>
    <div>
        <div style="width:100%;height:600px;background:#fff;">
            <p style="padding:30px;font-weight:600;">个人信息</p>
            <div style="width:50%;height:150px;margin-left:30px;float:left;line-height:30px">
                <p style="font-size:14px;">
                    <span style="color:#666666">真实姓名：</span>
                    <span>{{ userInfo.realName }}</span>
                </p>
                <p style="font-size:14px;">
                    <span style="color:#666666">性别：</span>
                    <span>{{ userInfo.sex == 1 ? '男' : '女' }}</span>
                </p>
                <p style="font-size:14px;">
                    <span style="color:#666666">QQ：</span>
                    <span>{{ userInfo.QQ }}</span>
                </p>
                <p style="font-size:14px;">
                    <span style="color:#666666">邮箱：</span>
                    <span>{{ userInfo.email }}</span>
                </p>
            </div>
            <div style="width:40%;height:150px;margin-left:30px;float:left;line-height:30px">
                <p style="font-size:14px;">
                    <span style="color:#666666">学校：</span>
                    <span>{{ userInfo.schoolName }}</span>
                </p>
                <p style="font-size:14px;">
                    <span style="color:#666666">院系：</span>
                    <span>{{ userInfo.departmentName }}</span>
                </p>
                <p style="font-size:14px;">
                    <span style="color:#666666">课程：</span>
                    <span v-for="item in userInfo.subjects">{{ item.subjectName  +' '}}</span>
                </p>
                <el-button type="primary" style="background-color: #76C8F6;border: none" @click="settingCourses"
                    size="mini">设置课程</el-button>
            </div>
            <div style="clear: both;"></div>
            <p style="padding:30px;font-weight:600;">账号信息</p>
            <p style="font-size:14px;padding-left:30px;">
                <span style="color:#666666">手机号：</span>
                <span>{{ userInfo.phoneNum }}</span>
            </p>
            <el-button type="primary" round style="background-color: #76C8F6;border: none;margin-top:30px;margin-left:30px;"
                @click="editPassWord">修改密码</el-button>
        </div>
        <el-dialog v-model="dialogVisible" title="修改密码" width="30%" :before-close="handleClose">
                <div class="centerDialog">
                     <el-form
                    label-position="right"
                    label-width="100px"
                    :model="forget"
                    style="max-width: 460px"
                    >
                    <el-form-item label="旧密码 :">
                        <el-input v-model="forget.oldPwd" placeholder="请输入旧密码" show-password />
                    </el-form-item>
                    <el-form-item label="新密码 :">
                        <el-input v-model="forget.newPwd" placeholder="请输入新密码" show-password />
                    </el-form-item>
                    <el-form-item label="新密码 :">
                        <el-input v-model="forget.configPwd" placeholder="请再次输入新密码" show-password />
                    </el-form-item>
                    </el-form>

                    <el-button type="primary" round style="background-color: #76C8F6;border: none;position:absolute;bottom:10%;left:40%;" @click="confirmPwd">确认修改</el-button>
                </div>
               
        </el-dialog>
        <el-dialog v-model="dialogSetting" title="设置课程" width="25%" :before-close="settingClose">
            <p style="margin-top: 10px;">
                <span>选择课程：</span>
                <el-select v-model="subjectIds" class="m-2" placeholder="请选择" size="small" multiple style="width: 70%;"
                    filterable @change="change">
                    <el-option v-for="item in courseData" :key="item.id" :label="item.subjectName" :value="item.id" />
                </el-select>
            </p>
            <p style="margin-top: 10px; color: #F50000;font-size: 12px;">
                *注：如老师所教课程已被管理员设置为班级课程，则该课程不可被修改，仅可添加，课程可多选
            </p>
            <template #footer>
                <div style="text-align: center;" class="dialog-footer">
                    <el-button @click="dialogSetting = false" round>取消</el-button>
                    <el-button type="primary" style="background-color: #76C8F6;border: none;" @click="confirmcourse" round>
                        确定
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted } from "vue";
// import router from "../../router/index.js";
import { F_Get_subscribeCourseForStudentId } from '@/js/teachingSquare'
import { setStorage, getStorage, Newdata } from "@/js/common";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { f_get_userInfo, get_select_subjectList, f_update_userPwd } from "@/js/homeListApi";
import { f_update_digitalTrainingStatus } from '@/js/teachingSquare'
import { ElMessage } from "element-plus";
export default {
    setup(prop, ctx) {
        const store = useStore();
        const router = useRouter();
        const state = reactive({
            value: '',
            options: [],
            dialogVisible: false,
            dialogSetting: false,
            courseData: [],
            courseName: '',//课程搜索名称
            subjectIds: [],
            userInfo: [],
            forget: {
                id: getStorage('userId'),
                oldPwd: '',
                newPwd: '',
                configPwd: ''
            }
        })
        const methods = {
            handleClose() {
                state.forget={
                    id: getStorage('userId'),
                    oldPwd: '',
                    newPwd: '',
                    configPwd: ''
                }
                state.dialogVisible = false;
            },
            editPassWord() {
                state.dialogVisible = true;
            },
            settingCourses() {
                state.dialogSetting = true
            },
            settingClose() {
                state.dialogSetting = false;
            },
            //获取个人信息
            userList() {
                const params = {
                    userId: getStorage('userId'),
                    userType: getStorage("StudentOrTeacher")
                }
                f_get_userInfo(params).then(res => {
                    console.log(res.data);
                    const { code, data } = res.data
                    if (code == 1) {
                        let reg = /^(\d{3})\d*(\d{4})$/;
                        data.phoneNum = data.phoneNum.replace(reg, '$1****$2')
                        state.userInfo = data;
                        ctx.emit("confirmcourse", state.userInfo);
                    }
                })
            },
            //获取课程列表
            courseList() {
                get_select_subjectList().then(res => {
                    const { code, data } = res.data
                    if (code == 1) {
                        state.courseData = data
                    }
                })
            },
            change(e) {
                state.courseId = e
            },
            //修改课程实训
            confirmcourse() {
                let str = state.subjectIds
                let staarr = str.toString()
                const params = {
                    id: getStorage('userId'),
                    subjectIds: staarr
                }
                f_update_digitalTrainingStatus(params).then(res => {
                    const { code, data } = res.data
                    console.log(res.data)
                    if (code == 1) {
                        ElMessage({
                            type: 'success',
                            message: '修改成功',
                        })
                        state.dialogSetting = false
                        methods.userList()
                    } else {
                        ElMessage({
                            type: 'error',
                            message: res.data.message,
                        })
                        state.dialogSetting = true
                    }
                })
            },
            //修改密码
            confirmPwd() {
                console.log(state.forget, 'forget')
                const { newPwd, oldPwd, configPwd } = state.forget
                if (newPwd == '' || oldPwd == '' || configPwd == '') {
                    ElMessage({
                        type: 'warning',
                        message: '密码不能为空',
                    })
                } else if (newPwd.length < 6 || configPwd < 6) {
                    ElMessage({
                        type: 'warning',
                        message: '密码长度不能小于6位',
                    })
                } else if (newPwd !== configPwd) {
                    ElMessage({
                        type: 'warning',
                        message: '两次密码输入不一致',
                    })
                }else if (newPwd == oldPwd) {
                    ElMessage({
                        type: 'warning',
                        message: '新密码不能和旧密码相同',
                    })
                } else {
                    f_update_userPwd(state.forget).then(res => {
                        console.log(res, 'res')
                        const { code, data } = res.data
                        console.log(res.data)
                        if (code == 1) {
                            ElMessage({
                                type: 'success',
                                message: '修改成功',
                            })
                            state.dialogVisible = false
                            setTimeout(() => {
                                setStorage("ftoken", "");
                                setStorage("detailData", "");
                                setStorage("bclist", "");
                                setStorage("account", "");
                                setStorage("password", "");
                                setStorage("userId", "");
                                setStorage("StudentOrTeacher", "");
                                router.push("/loginPage");
                            }, 2000);

                        } else {
                            ElMessage({
                                type: 'error',
                                message: res.data.message,
                            })
                            state.dialogVisible = true
                        }
                    })
                }
            }
        }
        onMounted(() => {
            methods.userList();
            methods.courseList()
        });
        return {
            ...toRefs(state),
            ...methods,
        };
    }
}
</script>
<style lang="scss" scoped>
:deep div::-webkit-scrollbar {
    width: 5px;
    /*滚动条宽度*/
    height: 10px;
    /*滚动条高度*/
}

/*定义滚动条轨道 内阴影+圆角*/
:deep div::-webkit-scrollbar-track {
    box-shadow: 0px 1px 3px #fff inset;
    /*滚动条的背景区域的内阴影*/
    border-radius: 10px;
    /*滚动条的背景区域的圆角*/
    background-color: #fff;
    /*滚动条的背景颜色*/
}

/*定义滑块 内阴影+圆角*/
:deep div::-webkit-scrollbar-thumb {
    box-shadow: 0px 1px 3px #76C8F6 inset;
    /*滚动条的内阴影*/
    border-radius: 10px;
    /*滚动条的圆角*/
    background-color: #76C8F6;
    /*滚动条的背景颜色*/
}

:deep .m-2 .el-input__inner {
    border-radius: 20px !important;
}
 .centerDialog{
    width: 30vw;
    height: 30vh;
  }
</style>