<template>
    <div class="questionBankTemplate">
        <div style="width:100%;height:600px;background:#fff;">
          <div class="questionHeader">
            <p @click="onQuest(1)" :class="showQuest==1?'questionActive':''">课程题库</p>
            <p @click="onQuest(2)" :class="showQuest==2?'questionActive':''">实训题库</p>
          </div>
          <!-- 搜索 -->
          <div class="searchList">
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
              <el-form-item label="课程名称:" v-if="showQuest==1">
                <el-select
                  v-model="formInline.couresValue"
                  size="mini"
                  placeholder="请选择课程名称"
                  filterable 
                  clearable
                  @change="regionChange">
                  <el-option
                  v-for="item in courseOptions"
                  :key="item.id"
                  :label="item.courseName"
                  :value="item.id"
                />
                </el-select>
              </el-form-item>
              <el-form-item label="章名称:" v-if="showQuest==1">
                <el-select
                  v-model="formInline.zhangValue"
                  placeholder="请选择章名称"
                  clearable
                  size="mini"
                  @change="zhangChange">
                  <el-option
                  v-for="item in zhangOptions"
                  :key="item.id"
                  :label="item.catalogueName"
                  :value="item.id"
                />
                </el-select>
              </el-form-item>
              <el-form-item label="节名称:" v-if="showQuest==1">
                <el-select
                  v-model="formInline.jieValue"
                  placeholder="请选择节名称"
                  size="mini"
                  clearable>
                  <el-option
                  v-for="item in jieOptions"
                  :key="item.id"
                  :label="item.catalogueName"
                  :value="item.id"
                />
                </el-select>
              </el-form-item>
                <el-form-item label="系统名称:" v-if="showQuest==2">
                  <el-select v-model="formInline.trainingSystemValue" size="mini" filterable clearable placeholder="请选择" @change="changeSystem">
                    <el-option
                            v-for="item in trainingSystemOptions"
                            :key="item.id"
                            :label="item.systemName"
                            :value="item.id"
                          />
                  </el-select>
              </el-form-item>
                <el-form-item label="模块名称:" v-if="showQuest==2">
                 <el-select v-model="formInline.trainingSystemModuleValue" size="mini" filterable placeholder="请选择">
                  <el-option
                          v-for="item in trainingSystemModuleOptions"
                          :key="item.id"
                          :label="item.moduleName"
                          :value="item.id"
                        />
                </el-select>
              </el-form-item>
              <el-form-item label="题型:">
                <el-select
                  v-model="formInline.tixingValue"
                  placeholder="请选择题型"
                  size="mini"
                  clearable>
                  <el-option
                  v-for="item in tixingOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
                </el-select>
              </el-form-item>
              <el-form-item label="难度:">
                <el-select
                  v-model="formInline.nanduValue"
                  placeholder="请选择难度"
                  size="mini"
                  clearable>
                  <el-option
                  v-for="item in nanduOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
                </el-select>
              </el-form-item>
              <el-form-item label="上传人:">
                <el-input size="mini" v-model="formInline.UpdataNmae" placeholder="请输入上传人" clearable />
              </el-form-item>
              <el-form-item label="题干:">
                <el-input size="mini" v-model="formInline.stemValue" placeholder="请输入题干" clearable />
              </el-form-item>
              <el-form-item>
                <el-button  size="mini" type="primary"   @click="searchClick">搜索</el-button>
              </el-form-item>
              <el-form-item style="border: none;float:right;margin-right:31px;border-radius: 2px;">
                <el-button size="mini" type="primary" @click="batchUpload">批量上传</el-button>
              </el-form-item>
              <el-form-item style="border: none;float:right;margin-right:20px;;border-radius: 2px;">
                <el-button size="mini" type="primary" @click="addQuestions">添加试题</el-button>
              </el-form-item>
            
            </el-form>
           
          </div>
          <el-table :data="tableData" stripe  height="480" max-height="394px">
            <el-table-column fixed type="index"  label="序号" />
            <el-table-column prop="courseName" label="课程名称" width="150" v-if="showQuest==1" />
            <el-table-column prop="courseChapterName" label="章名称"  width="150" v-if="showQuest==1"  />
            <el-table-column prop="courseSectionName" label="节名称"  width="150" v-if="showQuest==1" />
                 <el-table-column prop="systemName" label="系统名称" v-if="showQuest==2"  />
            <el-table-column prop="moduleName" label="模块名称" v-if="showQuest==2" />
            <el-table-column prop="topicType" label="题型" >
              <template #default="scope">
                <span>{{ scope.row.topicType == 1?'单选':scope.row.topicType == 2?'多选':scope.row.topicType == 3?'填空':scope.row.topicType == 4?'判断':'--' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="topicDifficulty" label="难度"  width="110" >
              <template #default="scope">
                <span>{{ scope.row.topicDifficulty == 1?'简单':scope.row.topicDifficulty == 2?'中等':scope.row.topicDifficulty == 3?'困难':'--' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="testQuestionContent" label="题干" width="150" />
            <el-table-column prop="correctAnswer" label="正确答案" width="150" >
              <template #default="scope">
                <span>
                  {{ scope.row.correctAnswer?.join(',') }}
                </span>
                <!-- <span v-for="(item,index) in scope.row.correctAnswer" :key="index">
                  <span>{{ item+',' }}</span>
                </span> -->
              </template>
            </el-table-column>
            <el-table-column prop="testQuestionAnalysis" label="解析" width="150" />
            <el-table-column prop="insertTime" label="上传时间" width="200" />
            <el-table-column prop="realName" label="上传人" width="200" />
            <el-table-column  label="操作" fixed="right" width="160" >
              <template #default="scope">
                <span style="cursor: pointer;color:#76C8F6;margin-right:20px" @click="seeClick(scope.row)" >查看</span>
                <span style="cursor: pointer;color:#76C8F6;margin-right:20px" @click="editClick(scope.row)" v-show="userId == scope.row.userId">编辑</span>
                <span style="cursor: pointer;color:#F50000;" @click="deleteClick(scope.row)" v-show="userId == scope.row.userId">删除</span>
              </template>
              
            </el-table-column>
          </el-table>
          <div style="display: flex;justify-content: center;width: 100%;height: 40px;align-items: center;">
            <el-pagination
            style="text-align:center"
            :current-page="currentPage1"
            :page-size="pageSize4"
            background
            layout="total, prev, pager, next"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            />
        </div>  
       </div>
       <addQuestions v-if="dialogFormVisible" :dialogFormVisible="dialogFormVisible" :dioanTitle="dioanTitle" :showQuest="showQuest" :ObjectRow="ObjectRow" @onClose="onClose"></addQuestions>
        <!-- 详情 -->
        <el-dialog v-model="detailDialog" title="试题详情"  width="40%">
          <el-form  size="small"  :label-position="labelPosition" label-width="100px">
            <el-form-item label="课程名称："  v-if="showQuest==1">
              {{ seeForm.courseName }}
            </el-form-item>
            <el-form-item label="章名称："  v-if="showQuest==1">
              {{ seeForm.courseChapterName }}
            </el-form-item>
            <el-form-item label="节名称：" v-if="showQuest==1" >
              {{ seeForm.courseSectionName }}
            </el-form-item>
             <el-form-item label="系统名称："  v-if="showQuest==2">
              {{ seeForm.systemName }}
            </el-form-item>
            <el-form-item label="模块名称：" v-if="showQuest==2" >
              {{ seeForm.moduleName }}
            </el-form-item>
            <el-form-item label="难度：" >
              {{ seeForm.topicDifficulty == 1?'简单':seeForm.topicDifficulty == 2?'中等':seeForm.topicDifficulty == 3?'困难':'' }}
            </el-form-item>
            <el-form-item label="选择题型：" >
              {{ seeForm.topicType == 1?'单选':seeForm.topicType == 2?'多选':seeForm.topicType == 3?'填空':seeForm.topicType == 4?'判断':''}}
            </el-form-item>
            <el-form-item label="题干：" >
              {{ seeForm.testQuestionContent }}
              <div style="display: flex;justify-content: flex-start;">
                <div style="margin-right: 10px;" v-for="(item,index) in seeForm.answers" :key="index">
                  {{ item.isOrder == 1?'A':item.isOrder == 2?'B':item.isOrder == 3?'C':item.isOrder == 4?'D': ''}} {{ item.content }}
                </div>
              
              </div>
            </el-form-item>
            <el-form-item label="答案：" >
              <span v-for="(item,index) in seeForm.testcorrect" :key="index" style="padding-right:10px;">
                {{ item.isOrder == 1?'A':item.isOrder == 2?'B':item.isOrder == 3?'C':item.isOrder == 4?'D': ''}}.{{ item.content }}
              </span>
              
            </el-form-item>
            <el-form-item label="解析：" >
              {{ seeForm.testQuestionAnalysis }}
            </el-form-item>
          </el-form>
        </el-dialog>
        <el-dialog v-model="daoruDialog" title="导入模板"  width="30%" >
          <el-form size="small" :model="formBatch" :rules="rules"  :inline="true" label-width="100px" ref="ruleFormRef">
            <el-form-item label="单选题模版：" >
              <el-button style="background-color: #76C8F6;border: none;" type="primary">
                
                <a
                :download="nowTime1"
                :href="DataFile1"
          
                style="color: #fff;"
                >下载</a>
                </el-button>
            </el-form-item>
            <el-form-item label="多选题模版：" >
              <el-button style="background-color: #76C8F6;border: none;" type="primary">
                <a
                :download="nowTime2"
                :href="DataFile2"
                style="color: #fff;"
                >下载</a>
                </el-button>
            </el-form-item>
            <el-form-item label="填空题模版：" >
              <el-button style="background-color: #76C8F6;border: none;" type="primary">
                <a
                :download="nowTime3"
                :href="DataFile3"
                style="color: #fff;"
                >下载</a>
                </el-button>
            </el-form-item>
            <el-form-item label="判断题模版：" >
              <el-button style="background-color: #76C8F6;border: none;" type="primary">
                <a
                :download="nowTime4"
                :href="DataFile4"
                style="color: #fff;"
                >下载</a>
                </el-button>
            </el-form-item>
            <el-form-item label="请选择上传模版题型：" label-width="170px" prop="uploadQuestions" class="uploadQuestion">
              <el-select
                  v-model="formBatch.uploadQuestions"
                  placeholder="请选择题型"
                  clearable
                >
                <el-option
                  v-for="item in formBatch.tixingOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
                </el-select>
            </el-form-item>
            <el-form-item label="上传已录入模版：" label-width="130px">
              <el-upload
                v-model:file-list="fileList" 
                ref="uploadrefss"
                accept=".xlsx"
                :auto-upload="false" :multiple="true"
                :on-change="importData" :on-remove="handleRemove" :limit="1">
              <el-button type="primary" style="background-color: #76C8F6;border: none;">上传</el-button>
              <template #tip>
                <div style="font-size: 14px;color: red;">
                  说明：需要先下载相应的模版，然后按照模版要求填写，填写完毕后，再将编辑好的模版上传，每次仅能上传一个已录入模版
                </div>
              </template>
            </el-upload>
              
            </el-form-item>
          </el-form>
          <template #footer>
        <div class="dialog-footer" style="text-align: center;">
               <el-button  @click="CancelSubmission">
                取消
                </el-button>
                <!-- :disabled="!formBatch.uploadQuestions||!fileRaw"  -->
                <el-button v-if="!isloading" :disabled="!formBatch.uploadQuestions||!fileRaw" style="background-color: #76C8F6;border: none;" type="primary" @click="uploadBatch">
                确定
                </el-button>
                <el-button v-else type="primary" loading class="loadClass">Loading</el-button>
            </div>
    </template>
        </el-dialog>
    </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted,ref} from "vue";
import {baseUrl} from '@/js/util.js'
import addQuestions from "./addQuestions";
import {f_get_courseQuestionBankPageList,get_select_courseList,get_select_courseChapterList,get_select_courseSectionList,f_delete_courseQuestion,DownloadCourseQuestionExcel,f_ImportExcel_courseQuestion,f_get_trainingQuestionBankPageList,get_select_trainingSystem,get_select_trainingSystemModule,f_ImportExcel_trainingQuestion,DownloadTrainingQuestionExcel} from '@/js/teachingSquare'
import { setStorage,getStorage,Newdata} from "@/js/common";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {courseData} from "@/js/caseApi";
import { ElMessage,ElMessageBox } from "element-plus";
export default {
    setup() {
    const store = useStore();
    const router = useRouter();
    const ruleFormRef=ref(null);
    const uploadrefss=ref(null);
    const state = reactive({
        isloading:false,
        baseUrl: baseUrl,
        userId:getStorage('userId'),
        formBatch:{
            fileList:null,
            uploadQuestions:'',
            tixingOptions:[
          {
            value:1,
            label:'单选'
          },{
            value:2,
            label:'多选'
          },{
            value:3,
            label:'填空'
          },{
            value:4,
            label:'判断'
          }
        ]
        },
        fileRaw:null,
        headers:{
                token:getStorage('ftoken'),
                operate:true
            },
        rules:{
            uploadQuestions:[{ required: true, message: '请选择题型', trigger: 'change' }]
        },
        nowTime1:'',
        DataFile1:'',
        nowTime2:'',
        DataFile2:'',
        nowTime3:'',
        DataFile3:'',
        nowTime4:'',
        DataFile4:'',
        showQuest:1,
        showQuestDiog:null,
        currentPage4:1,
        currentPage1:1,
        pageSize4:10,
        total:0,
        dialogFormVisible:false,//添加试题
        detailDialog:false,
        daoruDialog:false,
        labelPosition:'right',
        courseOptions:[],
        zhangOptions:[],
        jieOptions:[],
        ObjectRow:{},
        trainingSystemOptions:[],
        trainingSystemModuleOptions:[],
        tixingOptions:[
          {
            value:1,
            label:'单选'
          },{
            value:2,
            label:'多选'
          },{
            value:3,
            label:'填空'
          },{
            value:4,
            label:'判断'
          }
        ],
        nanduOptions:[
          {
            value:1,
            label:'简单'
          },{
            value:2,
            label:'中等'
          },{
            value:3,
            label:'困难'
          }
        ],
        formInline:{
          couresValue:null,
          zhangValue:null,
          jieValue:null,
          tixingValue:1,
          nanduValue:1,
          UpdataNmae:null,
          stemValue:null,
          trainingSystemValue:null,
          trainingSystemModuleValue:null,
        },
        seeForm:{},
        dioanTitle:'',
        tableData:[
        {
          name:'建筑力学',
          time:'第一章 ',
          classroom:'第二节',
          progress:'单选',
          chapters:'简单',
          className:'建筑力学…',
          exercise:'建筑力学…',
          shiti:'建筑力学…',
          data01:'2023-07-04 12:32:32',
          data02:'张三',
          data03:'智慧工地',
          data04:'质量管理',
        },{
          name:'建筑力学',
          time:'第一章 ',
          classroom:'第二节',
          progress:'单选',
          chapters:'简单',
          className:'建筑力学…',
          exercise:'建筑力学…',
          shiti:'建筑力学…',
          data01:'2023-07-04 12:32:32',
          data02:'王五',
          data03:'智慧工地',
          data04:'安全管理',
        },{
          name:'建筑力学',
          time:'第一章 ',
          classroom:'第二节',
          progress:'单选',
          chapters:'简单',
          className:'建筑力学…',
          exercise:'建筑力学…',
          shiti:'建筑力学…',
          data01:'2023-07-04 12:32:32',
          data02:'兆玉',
          data03:'智慧工地',
          data04:'人员管理',
        },{
          name:'建筑力学',
          time:'第一章 ',
          classroom:'第二节',
          progress:'单选',
          chapters:'简单',
          className:'建筑力学…',
          exercise:'建筑力学…',
          shiti:'建筑力学…',
          data01:'2023-07-04 12:32:32',
          data02:'刘强',
           data03:'智慧工地',
          data04:'安全管理',
        },
      ],
    })
    onMounted(()=>{
            methods.get_select_courseList();
            methods.get_select_trainingSystem();
        
      methods.f_get_courseQuestionBankPageList();

    })
    const methods = {
       // 系统名称
          get_select_trainingSystem(){
            get_select_trainingSystem('').then((res)=>{
              let {code,data}=res.data;
              if(code==1){
                state.trainingSystemOptions=data;
               
              }
            })
          },
          // 系统模块
          get_select_trainingSystemModule(){
            let datas={
              trainingSystemId:state.formInline.trainingSystemValue
            }
            get_select_trainingSystemModule(datas).then((res)=>{
              let {code,data}=res.data;
              if(code==1){
                state.trainingSystemModuleOptions=data;
              }
            })
          },
          // 系统下拉选择
          changeSystem(){
             state.formInline.trainingSystemModuleValue=null;
             methods.get_select_trainingSystemModule();
          },
 
  CancelSubmission(){
    state.fileRaw=''
   uploadrefss.value.clearFiles()
   state.daoruDialog=false;
  },
    // 文件移除
   handleRemove(uploadFile, uploadFiles){
    state.fileRaw=''
   uploadrefss.value.clearFiles();

    console.log(uploadFile, uploadFiles,'uploadFile, uploadFiles文件移除',state.fileRaw)
   },
    // 文件上传
    importData(file,fileLists){
        state.fileRaw=file.raw;
        console.log(file,'文件上传',file.raw,state.fileRaw)

    },
    // 批量上传
  async uploadBatch(){
        ruleFormRef.value.validate((valid)=>{
            if(valid){
                if(!state.fileRaw){
                    ElMessage({
                            type: 'info',
                            message: '请上传文件',
                        })
                }
          
                let formData=new FormData()
                formData.append('topicType',state.formBatch.uploadQuestions);
                formData.append('teacherId',getStorage('userId'));
                formData.append('file',state.fileRaw);
                state.isloading=true;
                if(state.showQuest==1){
                     f_ImportExcel_courseQuestion(formData).then((res)=>{
                    if(res.data.code==1){
                        ElMessage({
                            type: 'success',
                            message: '成功',
                            })
                            state.isloading=false;
                        state.daoruDialog=false;
                        uploadrefss.value.clearFiles()
                        methods.f_get_courseQuestionBankPageList();

                    }else{
                        console.log(res.data.message,488)
                        ElMessage({
                            type: 'info',
                            message: res.data.message,
                            })
                            state.isloading=false;
                        
                    }
                })
                }else if(state.showQuest==2){
                   f_ImportExcel_trainingQuestion(formData).then((res)=>{
                      if(res.data.code==1){
                          ElMessage({
                              type: 'success',
                              message: '成功',
                              })
                              state.isloading=false;
                          state.daoruDialog=false;
                          uploadrefss.value.clearFiles()
                          methods.f_get_courseQuestionBankPageList();

                      }else{
                          console.log(res.data.message,488)
                          ElMessage({
                              type: 'info',
                              message: res.data.message,
                              })
                              state.isloading=false;
                          
                      }
                  })
                    
                }
             
            }else{
                console.log("未通过");
            }
        })
    },
    // 批量上传 试题下载
    downloadTemplate(id,titles){
        let params={
            topicType:id
        }
        if(state.showQuest==1){
           DownloadCourseQuestionExcel(params).then(res=>{
            if(res.status == 200){
                  let blob=new Blob([res.data],{type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                  let urls=URL.createObjectURL(blob);
                  if(id==1){
                      state.DataFile1=urls;
                      state.nowTime1=titles
                  }else if(id==2){
                      state.DataFile2=urls;
                      state.nowTime2=titles
                  }else if(id==3){
                      state.DataFile3=urls;
                      state.nowTime3=titles
                  }else if(id==4){
                      state.DataFile4=urls;
                      state.nowTime4=titles
                  }

              }
          })
        }else if(state.showQuest==2){
           DownloadTrainingQuestionExcel(params).then(res=>{
            if(res.status == 200){
                  let blob=new Blob([res.data],{type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                  let urls=URL.createObjectURL(blob);
                  if(id==1){
                      state.DataFile1=urls;
                      state.nowTime1=titles
                  }else if(id==2){
                      state.DataFile2=urls;
                      state.nowTime2=titles
                  }else if(id==3){
                      state.DataFile3=urls;
                      state.nowTime3=titles
                  }else if(id==4){
                      state.DataFile4=urls;
                      state.nowTime4=titles
                  }

              }
          })
        }
       
    },
      // 删除试题
      deleteClick(row){
        ElMessageBox.confirm(
                        '确认要删除此数据吗',
                        '删除',
                        {
                        confirmButtonText: '确认',
                        cancelButtonText: '取消',
                        type: 'warning',
                        }).then(() => {
                           
                          f_delete_courseQuestion(row.id).then(res=>{
                                if (res.data.code == 1) {
                                    ElMessage({
                                        type: 'success',
                                        message: '删除成功',
                                    })
                                    methods.f_get_courseQuestionBankPageList()
                                }
                            })

                        
                        })
                        .catch(() => {
                        ElMessage({
                            type: 'info',
                            message: '取消删除',
                        })
                        })
      },
      // 章监听事件
      zhangChange(event){
        state.formInline.jieValue = '';
        let params = {
          courseChapterId:event,
        }
        get_select_courseSectionList(params).then(res=>{
          if (res.data.code == 1) {
            state.jieOptions = res.data.data;
          }
        })
      },
      // 课程监听事件
      regionChange(event){
        state.formInline.zhangValue = '';
        state.formInline.jieValue = '';
        let params = {
          courseId:event,
        }
        get_select_courseChapterList(params).then(res=>{
          if (res.data.code == 1) {
            state.zhangOptions = res.data.data;
          }
        })
      },
      // 获取课程下拉数据
      get_select_courseList(){
        let params = {
          courseName:'',
        }
        get_select_courseList(params).then(res=>{
          if (res.data.code == 1) {
            state.courseOptions = res.data.data;
          }
        })
      },
      // 获取课程题库的列表数据
      f_get_courseQuestionBankPageList(){
        if(state.showQuest==1){
            this.courseQuestionBankPageListfn()
        }else if(state.showQuest==2){
            this.f_get_trainingQuestionBankPageList()
        }
      },
      // 获取课程题库的列表数据
      courseQuestionBankPageListfn(){
         let params = {
          courseId:state.formInline.couresValue,
          courseChapterId:state.formInline.zhangValue,
          courseSectionId:state.formInline.jieValue,
          topicType:state.formInline.tixingValue,
          topicDifficulty:state.formInline.nanduValue,
          user_name:state.formInline.UpdataNmae,
          questionContent:state.formInline.stemValue,
          pageIndex:state.currentPage1,
          pageSize:state.pageSize4
        }
        f_get_courseQuestionBankPageList(params).then(res=>{
          if (res.data.code == 1) {
            state.tableData = res.data.data.list;
            state.tableData.forEach(element => {
              element.correctAnswer = [];
              element.answers.forEach(element2 => {
                if (element2.isAnswer) {
                  element.correctAnswer.push(element2.content);
                }
              });
             
            });
            state.total = res.data.data.totalCount.Value;
          }
          
        })
      },
      // 获取实训课程题库的列表数据
      f_get_trainingQuestionBankPageList(){
         let params = {
          trainingSystemId:state.formInline.trainingSystemValue,
          trainingSystemModuleId:state.formInline.trainingSystemModuleValue,
          topicType:state.formInline.tixingValue,
          topicDifficulty:state.formInline.nanduValue,
          user_name:state.formInline.UpdataNmae,
          questionContent:state.formInline.stemValue,
          pageIndex:state.currentPage1,
          pageSize:state.pageSize4
        }
        f_get_trainingQuestionBankPageList(params).then(res=>{
          if (res.data.code == 1) {
            state.tableData = res.data.data.list;
            state.tableData.forEach(element => {
              element.correctAnswer = [];
              element.answers.forEach(element2 => {
                if (element2.isAnswer) {
                  element.correctAnswer.push(element2.content);
                }
              });
             
            });
            state.total = res.data.data.totalCount.Value;
          }
          
        })

      },
      // 搜索
      searchClick(){
        state.currentPage1 = 1;
        methods.f_get_courseQuestionBankPageList();
      },
      handleSizeChange(val){
                state.pageSize4 = val;
                methods.f_get_courseQuestionBankPageList();
            },
            handleCurrentChange(val){
                state.currentPage1 = val;
                methods.f_get_courseQuestionBankPageList();
            },
      // 切换题库
      onQuest(id){
        state.showQuest=id;
        if (state.showQuest == 1) {
          methods.f_get_courseQuestionBankPageList()
        }else{
          state.tableData = [];
          state.total = 0;
           methods.f_get_courseQuestionBankPageList()
        }
      },
      // 添加试题
      addQuestions(){
        state.dialogFormVisible=true;
        state.dioanTitle  = '添加试题'
        state.showQuestDiog = state.showQuest;
        console.log(state.dialogFormVisible,'state.dialogFormVisible')
      },
      // 编辑试题
      editClick(row){
        state.dialogFormVisible=true;
        state.dioanTitle  = '编辑试题';
        state.ObjectRow = row;
        state.showQuestDiog = state.showQuest;
      },
      seeClick(row){
        state.detailDialog = true;
        state.seeForm = row;
        state.seeForm.testcorrect = [];
        state.seeForm.answers.forEach(element => {
          if (element.isAnswer) {
            state.seeForm.testcorrect.push(element)
          }
        });
      },
      // 上传
      batchUpload(){
        state.daoruDialog = true;
        state.formBatch.uploadQuestions='';
        state.fileRaw='';
              methods.downloadTemplate(1,'单选题模版');
            methods.downloadTemplate(2,'多选题模版');
            methods.downloadTemplate(3,'填空题模版');
            methods.downloadTemplate(4,'判断题模版');
      },
      onClose(value){
        state.dialogFormVisible = false;
        console.log(state.dialogFormVisible,'state.dialogFormVisible6822')
        methods.f_get_courseQuestionBankPageList();
      },
      
    }
    return {
      ...toRefs(state),
      ...methods,
      ruleFormRef,
      uploadrefss,
    };
    },
    components:{
      addQuestions
    }
}
</script>
<style lang="scss" scoped>
    .dialog-footer{
      .el-button{
        border-radius: 20px;
      }
      .loadClass{
        border-radius: 20px;
        color: #76C8F6;
        background: #D0EDFD;
      }
    }
    :deep div::-webkit-scrollbar {
        width: 5px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
    }
    /*定义滚动条轨道 内阴影+圆角*/
    :deep div::-webkit-scrollbar-track {
        box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
        border-radius: 10px; /*滚动条的背景区域的圆角*/
        background-color: #fff; /*滚动条的背景颜色*/
    }
    /*定义滑块 内阴影+圆角*/
    :deep div::-webkit-scrollbar-thumb {
        box-shadow: 0px 1px 3px #76C8F6 inset; /*滚动条的内阴影*/
        border-radius: 10px; /*滚动条的圆角*/
        background-color: #76C8F6; /*滚动条的背景颜色*/
    }
    .questionBankTemplate{
      .uploadQuestion{
        display: block;
        display: flex;
       
      }
     
      .questionHeader{
        padding: 0 20px;
        height: 56px;
        line-height: 56px;
        box-sizing: border-box;
        display: flex;
        p{
          margin-right: 44px;
          font-size: 16px;
          font-weight: 500;
          box-sizing: border-box;
          cursor: pointer;
        }
        .questionActive{
        color: #76C8F6;
        }
      }
      .searchList{
        padding: 0 20px 10px 24px;
        box-sizing: border-box;
        .el-form-item{
          margin-bottom: 10px;
          :deep .el-button{
              border:none;
              background: #76C8F6;
          }
        }
        :deep .el-input{
          width: 146px;
          margin-right: 21.4px;
          box-sizing: border-box;
        }
      }
     :deep .el-table thead th{
        background-color: var(--el-border-color-extra-light);
      }
     
    }
</style>