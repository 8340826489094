import axios from 'axios';
import {baseUrl} from '@/js/util.js'


//获取当前老师的任务
export const f_get_teacherTaskPageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_get_teacherTaskPageList`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}



//获取当前学生的任务
export const f_get_studentTaskPageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_studentUser/f_get_studentTaskPageList`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}
//完成授课
export const f_update_teacherTaskStatus=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Training_API/api/F_teacherUser/f_update_teacherTaskStatus`,
        data:datas,
        headers:{
            "Content-Type":"application/json"
        }
    })
}

//获取用户所有未读信息
export const f_get_userMessage=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_user/f_get_userMessage`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}


//修改用户消息状态
export const f_update_userMsgStatus=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Training_API/api/F_user/f_update_userMsgStatus`,
        params:datas,
        headers: {'isFrontEnt':true},
    })
}