<template>
  <!-- Form -->
  <div>
     <el-dialog v-model="dialogFormVisible" :title="dioanTitle" :show-close="false" :before-close="handleClose">
    <el-form ref="ruleFormRef" :model="form" size="small" :rules="rules"  :label-position="labelPosition" label-width="100px">
      <el-form-item label="课程名称：" v-if="showQuest == 1" prop="couresValue">
        <el-select
                  v-model="form.couresValue"
                  placeholder="请选择课程名称"
                  filterable 
                  clearable
                  @change="regionChange">
                  <el-option
                  v-for="item in courseOptions"
                  :key="item.id"
                  :label="item.courseName"
                  :value="item.id"
                  :disabled="item.status == 1?false:true"
                />
                </el-select>
      </el-form-item>
      <el-form-item label="章名称:" v-if="showQuest == 1" prop="zhangValue">
        <el-select v-model="form.zhangValue" placeholder="请选择"  @change="zhangChange">
          <el-option
                  v-for="item in zhangOptions"
                  :key="item.id"
                  :label="item.catalogueName"
                  :value="item.id"
                />
        </el-select>
      </el-form-item>
      <el-form-item label="系统名称" v-if="showQuest == 2" prop="trainingSystemValue">
        <el-select v-model="form.trainingSystemValue" filterable clearable placeholder="请选择" @change="changeSystem">
          <el-option
                  v-for="item in trainingSystemOptions"
                  :key="item.id"
                  :label="item.systemName"
                  :value="item.id"
                  :disabled="item.status == 1?false:true"
                />
        </el-select>
      </el-form-item>
       <el-form-item label="系统模块" v-if="showQuest == 2" prop="trainingSystemModuleValue">
        <el-select v-model="form.trainingSystemModuleValue" filterable placeholder="请选择">
          <el-option
                  v-for="item in trainingSystemModuleOptions"
                  :key="item.id"
                  :label="item.moduleName"
                  :value="item.id"
                />
        </el-select>
      </el-form-item>
      <el-form-item label="节名称" v-if="showQuest == 1" prop="jieValue">
        <el-select
                  v-model="form.jieValue"
                  placeholder="请选择节名称"
                  clearable>
                  <el-option
                  v-for="item in jieOptions"
                  :key="item.id"
                  :label="item.catalogueName"
                  :value="item.id"
                />
                </el-select>
      </el-form-item>
     
      <el-form-item label="难度：" prop="nanduValue">
        <el-select
                  v-model="form.nanduValue"
                  placeholder="请选择难度"
                  clearable>
                  <el-option
                  v-for="item in nanduOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
                </el-select>
      </el-form-item>
      <el-form-item label="选择题型：" prop="tixingValue">
        <el-select
                  v-model="form.tixingValue"
                  placeholder="请选择题型"
                  @change="selectChange"
                  clearable>
                  <el-option
                  v-for="item in tixingOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
                </el-select>
      </el-form-item>
      <el-form-item label="题干：" prop="textareaTiGan" >
        <el-input
        v-model="form.textareaTiGan"
        :autosize="{ minRows: 4, maxRows: 4 }"
        type="textarea"
        placeholder="请输入"
      />
      <!-- 单选题 -->
      <div style="margin-top: 10px;display: flex;justify-content: flex-start;" v-if="daanShow == 1">
        <div style="margin-right: 10px;" > 
      <el-radio-group v-model="form.isAnswer" style="margin-left: 10px;" @change="RadioChange">
        <el-radio v-for="(item,index) in methodsultipleChoice" :key="index" :label="item.option">
          正确答案
          {{ item.option }}: <el-input v-model="item.content" style="width: 100px;" placeholder="请输入"/>
        </el-radio>
      </el-radio-group>
    </div>
   
      </div>
      <!-- 多选题 -->
      <div style="margin-top: 10px;display: flex;justify-content: flex-start;"  v-if="daanShow == 2">
        <div style="margin-right: 10px;">
          <el-checkbox-group v-model="form.checkList" @change="checkboxChange">
            <el-checkbox v-for="(item2,index2) in MultipleChoice" :key="index2" :label="item2.option" >
            正确答案
            {{ item2.option }}: <el-input v-model="item2.content" style="width: 100px;" placeholder="请输入"/>
            </el-checkbox>
          </el-checkbox-group>
          
         
        </div>
      </div>
      <!-- 判断题 -->
      <div style="margin-top: 10px;display: flex;justify-content: flex-start;"  v-if="daanShow == 4">
        <div style="margin-right: 10px;"> 
          <el-radio-group v-model="form.determine" style="margin-left: 10px;" @change="determineChange">
        <el-radio v-for="(item,index) in determineList" :key="index" :label="item.option">
          正确答案
          {{ item.option }}: <el-input v-model="item.content" style="width: 100px;" placeholder="请输入"/>
        </el-radio>
      </el-radio-group>
    </div>
   
      </div>
      <!-- 填空题 -->
      <div style="margin-top: 10px;display: flex;justify-content: flex-start;"  v-if="daanShow == 3">
        <div style="margin-right: 10px;">正确答案:  
          <el-input
        v-model="form.textareattiankong"
        placeholder="请输入"
      />
      
    </div>
      </div>
      </el-form-item>
      <el-form-item label="解析：" prop="jiexiValue">
        <el-input
        v-model="form.jiexiValue"
        :autosize="{ minRows: 4, maxRows: 4 }"
        type="textarea"
        placeholder="请输入"
      />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer" style="text-align: center;">
        <el-button @click="onClose">取消</el-button>
                <el-button style="background-color: #76C8F6;border: none;" type="primary" @click="addClick">
                确定
                </el-button>
            </div>
     
    </template>
  </el-dialog>
  </div>
 
</template>

<script>
import { reactive,toRefs, ref, onMounted, onUnmounted } from 'vue'
import { getStorage, setStorage } from "@/js/common";
import {get_select_courseList,get_select_courseChapterList,get_select_courseSectionList,f_update_courseQuestion,f_update_trainingQuestion,get_select_trainingSystem,get_select_trainingSystemModule} from '@/js/teachingSquare'
import { ElMessage,ElMessageBox } from "element-plus";
const formLabelWidth = '140px'
export default {
  props:{
    dialogFormVisible:Boolean,
    showQuest:Number,
    dioanTitle:String,
    ObjectRow:Object
  },
    setup(props,context) {
      const ruleFormRef = ref(null)
        const state=reactive({
          labelPosition:'right',
          radio:'',
          radio1:'',
          radio2:'',
          radio3:'',
          courseOptions:[],
          zhangOptions:[],
          jieOptions:[],
          trainingSystemOptions:[],
          trainingSystemModuleOptions:[],
          methodsultipleChoice:[
            {
              option:'A',
              content:'',
            },{
              option:'B',
              content:'',
            },{
              option:'C',
              content:'',
            },{
              option:'D',
              content:'',
            },
          ],
          MultipleChoice:[
            {
              option:'A',
              content:'',
              isAnswer:false,
            },{
              option:'B',
              content:'',
              isAnswer:false,
            },{
              option:'C',
              content:'',
              isAnswer:false,
            },{
              option:'D',
              content:'',
              isAnswer:false,
            }
          ],
          determineList:[
            {
              option:'A',
              content:'',
              isAnswer:false,
            },{
              option:'B',
              content:'',
              isAnswer:false,
            }
          ],
          fillBlanks:[],
          tixingOptions:[
          {
            value:1,
            label:'单选'
          },{
            value:2,
            label:'多选'
          },{
            value:3,
            label:'填空'
          },{
            value:4,
            label:'判断'
          }
        ],
        nanduOptions:[
          {
            value:1,
            label:'简单'
          },{
            value:2,
            label:'中等'
          },{
            value:3,
            label:'困难'
          }
        ],
            form:{
              trainingSystemValue:null,
              trainingSystemModuleValue:null,
              couresValue:null,
              zhangValue:null,
              jieValue:null,
              tixingValue:1,
              nanduValue:null,
              textareaTiGan:'',
              isAnswer:'',
              checkList:[],
              determine:'',
              textareaA:'',
              textareaB:'',
              textareaC:'',
              textareaD:'',
              textareattiankong:'',
              jiexiValue:'',
            },
            rules:{
              couresValue:[{ required: true, message: '请选择课程', trigger: 'change' }],
              trainingSystemValue:[{ required: true, message: '请选择系统名称', trigger: 'change' }],
              trainingSystemModuleValue:[{ required: true, message: '请选择系统模块', trigger: 'change' }],
              zhangValue:[{ required: true, message: '请选择章', trigger: 'change' }],
              jieValue:[{ required: true, message: '请选择节', trigger: 'change' }],
              tixingValue:[{ required: true, message: '请选择题型', trigger: 'change' }],
              nanduValue:[{ required: true, message: '请选择难度', trigger: 'change' }],
              textareaTiGan:[{ required: true, message: '请输入题干', trigger: 'blur' }],
              jiexiValue:[{ required: true, message: '请输入解析', trigger: 'blur' }]
            },
            daanShow:1,
            
        })
        onMounted(()=>{
          if(props.showQuest==1){
            methods.get_select_courseList();
          }else{
            methods.get_select_trainingSystem()
          }
          
          
          console.log(props.ObjectRow,props.showQuest);
          if (props.dioanTitle == '编辑试题') {
            state.form.couresValue = props.ObjectRow.courseId;
            state.form.zhangValue = props.ObjectRow.courseChapterId;
            state.form.jieValue = props.ObjectRow.courseSectionId;
            state.form.tixingValue = props.ObjectRow.topicType;
            state.daanShow =props.ObjectRow.topicType;
            state.form.nanduValue = props.ObjectRow.topicDifficulty;
            state.form.textareaTiGan = props.ObjectRow.testQuestionContent;
            state.form.jiexiValue = props.ObjectRow.testQuestionAnalysis;
            state.form.trainingSystemValue=props.ObjectRow.trainingSystemId;
            if(props.showQuest==1){
               methods.get_select_courseChapterList(state.form.couresValue);
            methods.get_select_courseSectionList(state.form.zhangValue);
            }else if(props.showQuest==2){
              state.form.trainingSystemModuleValue=props.ObjectRow.trainingSystemModuleId
              methods.get_select_trainingSystemModule()
            }
           
            // 填空
            if (props.ObjectRow.topicType == 3) {
              state.form.textareattiankong = props.ObjectRow.answers[0].content;
            }else if(props.ObjectRow.topicType == 1){
              // 单选
              props.ObjectRow.answers.forEach(element => {
                if (element.isOrder == 1) {
                     element.option = 'A';
                  }else if(element.isOrder == 2){
                    element.option = 'B';
                  }else if(element.isOrder == 3){
                    element.option = 'C';
                  }else{
                    element.option = 'D';
                  }
                if (element.isAnswer) {
                  if (element.isOrder == 1) {
                    state.form.isAnswer = 'A';
                  }else if(element.isOrder == 2){
                   state.form.isAnswer = 'B';
                  }else if(element.isOrder == 3){
                   state.form.isAnswer = 'C';
                  }else{
                   state.form.isAnswer = 'D';
                  }
                }
              });
              state.methodsultipleChoice = props.ObjectRow.answers;
            }else if(props.ObjectRow.topicType == 2){
              // 多选
              props.ObjectRow.answers.forEach(element => {
                if (element.isOrder == 1) {
                     element.option = 'A';
                  }else if(element.isOrder == 2){
                    element.option = 'B';
                  }else if(element.isOrder == 3){
                    element.option = 'C';
                  }else{
                    element.option = 'D';
                  }
                if (element.isAnswer) {
                  if (element.isOrder == 1) {
                    state.form.checkList.push('A')
                  }else if(element.isOrder == 2){
                    state.form.checkList.push('B')
                  }else if(element.isOrder == 3){
                    state.form.checkList.push('C')
                  }else{
                    state.form.checkList.push('D')
                  }
                }
              });
              state.MultipleChoice = props.ObjectRow.answers;
              console.log(state.form.checkList,state.MultipleChoice);
            }else if(props.ObjectRow.topicType == 4){
              // 判断
              props.ObjectRow.answers.forEach(element => {
                if (element.isOrder == 1) {
                     element.option = 'A';
                  }else if(element.isOrder == 2){
                    element.option = 'B';
                  }
                if (element.isAnswer) {
                  if (element.isOrder == 1) {
                    state.form.determine = 'A';
                  }else if(element.isOrder == 2){
                   state.form.determine = 'B';
                  }
                }
              });
              state.determineList = props.ObjectRow.answers;
            }
          }
        })
        const methods = {
          // 系统名称
          get_select_trainingSystem(){
            get_select_trainingSystem('').then((res)=>{
              let {code,data}=res.data;
              if(code==1){
                state.trainingSystemOptions=data;
               
              }
            })
          },
          // 系统模块
          get_select_trainingSystemModule(){
            let datas={
              trainingSystemId:state.form.trainingSystemValue
            }
            get_select_trainingSystemModule(datas).then((res)=>{
              let {code,data}=res.data;
              if(code==1){
                state.trainingSystemModuleOptions=data;
              }
            })
          },
          // 系统下拉选择
          changeSystem(){
             state.form.trainingSystemModuleValue=null;
             methods.get_select_trainingSystemModule();
          },
          // 关闭前的回调
          handleClose(){
            context.emit("onClose",false)
          },
          // 单选
          RadioChange(event){
            console.log(state.form.isAnswer);
            state.methodsultipleChoice.forEach((element,index) => {
              element.isOrder = index+1;
              if (event == element.option) {
                element.isAnswer = true
              }else{
                element.isAnswer = false
              }
            });
            console.log(state.methodsultipleChoice);
          },
          // 多选
          checkboxChange(event){
            state.MultipleChoice.forEach((element,index) => {
              element.isOrder = index+1;
              console.log(element.option);
             let data =  event.find(item => item == element.option)
                if(data){                                                                                                                                                                                                                  
                  element.isAnswer = true;
                }else{
                  element.isAnswer = false;
                }
            });
            console.log(state.MultipleChoice);
          },
          // 判断
          determineChange(event){
            state.determineList.forEach((element,index) => {
              element.isOrder = index+1;
              if (event == element.option) {
                element.isAnswer = true
              }else{
                element.isAnswer = false
              }
            });
            console.log(state.determineList);
          },
          // 确定
          addClick(){
            ruleFormRef.value.validate((valid) => {
              if (valid) {
                let arrayData = [];
                if (state.daanShow == 1) {
                  // 多选
                  if (props.dioanTitle == '添加试题') {
                    state.methodsultipleChoice.forEach(element => {
                    arrayData.push({
                      content:element.content,
                      isAnswer:element.isAnswer,
                      isOrder:element.isOrder
                    })
                  });
                  }else{
                    state.methodsultipleChoice.forEach(element => {
                    arrayData.push({
                      content:element.content,
                      isAnswer:element.isAnswer,
                      isOrder:element.isOrder,
                      id:element.id
                    })
                  });
                  }
                   
                }else if(state.daanShow == 2){
                  // 单选
                  if (props.dioanTitle == '添加试题') {
                    state.MultipleChoice.forEach(element => {
                    arrayData.push({
                      content:element.content,
                      isAnswer:element.isAnswer,
                      isOrder:element.isOrder
                    })
                  });
                  }else{
                    state.MultipleChoice.forEach(element => {
                    arrayData.push({
                      content:element.content,
                      isAnswer:element.isAnswer,
                      isOrder:element.isOrder,
                      id:element.id
                    })
                  });
                  }
                  
                }else if(state.daanShow == 4){
                  // 判断
                  if (props.dioanTitle == '添加试题') {
                    state.determineList.forEach(element => {
                    arrayData.push({
                      content:element.content,
                      isAnswer:element.isAnswer,
                      isOrder:element.isOrder
                    })
                  });
                  }else{
                    state.determineList.forEach(element => {
                    arrayData.push({
                      content:element.content,
                      isAnswer:element.isAnswer,
                      isOrder:element.isOrder,
                      id:element.id
                    })
                  });
                  }
                 
                }else{
                  // 填空
                  if (props.dioanTitle == '添加试题') {
                    arrayData.push({
                      content:state.form.textareattiankong,
                      isAnswer:true,
                      isOrder:1
                  })
                  }else{
                    arrayData.push({
                      content:state.form.textareattiankong,
                      isAnswer:true,
                      isOrder:1,
                      id:props.ObjectRow.answers[0].id
                  })
                  }
                  
                }
               let params = {};
               if(props.showQuest==1){
                 if (props.dioanTitle == '添加试题') {
                  params = {
                    courseId:state.form.couresValue,
                    courseChapterId:state.form.zhangValue,
                    courseSectionId:state.form.jieValue,
                    topicType:state.form.tixingValue,
                    topicDifficulty:state.form.nanduValue,
                    testQuestionContent:state.form.textareaTiGan,
                    testQuestionAnalysis:state.form.jiexiValue,
                    userId:getStorage('userId'),
                    questionBankAnswers:arrayData
                  }
                }else{
                  params = {
                    id:props.ObjectRow.id,
                    courseId:state.form.couresValue,
                    courseChapterId:state.form.zhangValue,
                    courseSectionId:state.form.jieValue,
                    topicType:state.form.tixingValue,
                    topicDifficulty:state.form.nanduValue,
                    testQuestionContent:state.form.textareaTiGan,
                    testQuestionAnalysis:state.form.jiexiValue,
                    userId:getStorage('userId'),
                    questionBankAnswers:arrayData
                  }
                }
                   methods.f_update_courseQuestion(params)
               }else{
                 if (props.dioanTitle == '添加试题') {
                  params = {
                    trainingSystemId:state.form.trainingSystemValue,
                    trainingSystemModuleId:state.form.trainingSystemModuleValue,
                    topicType:state.form.tixingValue,
                    topicDifficulty:state.form.nanduValue,
                    testQuestionContent:state.form.textareaTiGan,
                    testQuestionAnalysis:state.form.jiexiValue,
                    userId:getStorage('userId'),
                    questionBankAnswers:arrayData
                  }
                }else{
                  params = {
                    id:props.ObjectRow.id,
                    trainingSystemId:state.form.trainingSystemValue,
                    trainingSystemModuleId:state.form.trainingSystemModuleValue,
                    topicType:state.form.tixingValue,
                    topicDifficulty:state.form.nanduValue,
                    testQuestionContent:state.form.textareaTiGan,
                    testQuestionAnalysis:state.form.jiexiValue,
                    userId:getStorage('userId'),
                    questionBankAnswers:arrayData
                  }
                }
                methods.f_update_trainingQuestion(params)

               }
               
              }else{
                console.log('验证未通过');
              }
            })
          },
          // 添加、修改课程题库
          f_update_courseQuestion(params){
            f_update_courseQuestion(params).then(res=>{
                  console.log(res);
                  if (res.data.code == 1) {
                          ElMessage({
                            type: 'success',
                            message: '成功',
                            })
                            methods.onClose();
                  }
                })
          },
           // 添加、修改课程题库
          f_update_trainingQuestion(params){
            f_update_trainingQuestion(params).then(res=>{
                  console.log(res);
                  if (res.data.code == 1) {
                          ElMessage({
                            type: 'success',
                            message: '成功',
                            })
                            methods.onClose();
                  }
                })
          },
          selectChange(e){
           state.daanShow = e
          },
          onClose(){
            context.emit("onClose",false)
          },
           // 章监听事件
      zhangChange(event){
        state.form.jieValue = '';
        let params = {
          courseChapterId:event,
        }
        get_select_courseSectionList(params).then(res=>{
          if (res.data.code == 1) {
            state.jieOptions = res.data.data;
          }
        })
      },
      get_select_courseSectionList(id){
        let params = {
          courseChapterId:id,
        }
        get_select_courseSectionList(params).then(res=>{
          if (res.data.code == 1) {
            state.jieOptions = res.data.data;
          }
        })
      },
           // 课程监听事件
        regionChange(event){
          state.form.zhangValue = null;
          state.form.jieValue = null;
          let params = {
            courseId:event,
          }
          get_select_courseChapterList(params).then(res=>{
            if (res.data.code == 1) {
              state.zhangOptions = res.data.data;
            }
          })
        },
        get_select_courseChapterList(id){
          let params = {
            courseId:id,
          }
          get_select_courseChapterList(params).then(res=>{
            if (res.data.code == 1) {
              state.zhangOptions = res.data.data;
            }
          })
        },
           // 获取课程下拉数据
        get_select_courseList(){
          let params = {
            courseName:'',
          }
          get_select_courseList(params).then(res=>{
            if (res.data.code == 1) {
              state.courseOptions = res.data.data;
            }
          })
        },
        }
       
        return {
      ...toRefs(state),
      ...methods,
      ruleFormRef
    };
    }
    
}
// const form = reactive({
//   name: '',
//   region: '',
//   date1: '',
//   date2: '',
//   delivery: false,
//   type: [],
//   resource: '',
//   desc: '',
// })


</script>
<style lang="scss" scoped>
.el-button--text {
  margin-right: 15px;
}
.el-select {
  width: 300px;
}
.el-input {
  width: 300px;
}
.dialog-footer button:first-child {
  margin-right: 10px;
}
</style>