<template>
    <div>
        <div style="width:100%;height:600px;background:#fff;">
         
          <el-table :data="tableData" stripe style="width: 100%;height:550px" max-height="550px">
            <el-table-column label="序号" type="index" width="80" />
            <el-table-column prop="className" label="班级" />
            <el-table-column prop="subjectName" label="科目"  />
            <el-table-column prop="courseName" label="课程名称" />
            <el-table-column prop="percent" label="课程进度" />
            <el-table-column  label="操作" fixed="right"  width="120">
              <template #default='scope'>
                <!-- <span style="cursor: pointer;color:#76C8F6">去授课</span> -->
                <span style="cursor: pointer;color:#76C8F6" @click="classDetail(scope.row)">详情</span>
              </template>
              
            </el-table-column>
          </el-table>
          <div style="display: flex;justify-content: center;width: 100%;height: 40px;align-items: center;">
            <el-pagination
            style="text-align:center"
            :current-page="currentPage1"
            :page-size="pageSize4"
            background
            layout="total, prev, pager, next"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            />
        </div> 
       </div>
    </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted } from "vue";
// import router from "../../router/index.js";
import {f_get_teacherByClassNum} from '@/js/teachingSquare'

import { setStorage,getStorage,Newdata} from "@/js/common";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {courseData} from "@/js/caseApi";
export default {
    setup(prop,context) {
        const store = useStore();
    const router = useRouter();
    const state = reactive({
        tableData:[],
        currentPage1:1,
        pageSize4:10,
        total:0
    })
    onMounted(()=>{
      methods.f_get_teacherByClassNum();
    })
    const methods = {
      f_get_teacherByClassNum(){
        let params = {
          teacherId:getStorage('userId'),
          pageIndex:state.currentPage1,
          pageSize:state.pageSize4
        } 
        f_get_teacherByClassNum(params).then(res=>{
          if (res.data.code == 1) {
            state.tableData = res.data.data.list;
            state.total = res.data.data.totalCount.Value;
          }
        })
      },
      handleSizeChange(val){
                state.pageSize4 = val;
                methods.f_get_teacherByClassNum();
            },
            handleCurrentChange(val){
                state.currentPage1 = val;
                methods.f_get_teacherByClassNum();
            },
      classDetail(row){
        setStorage('classDetailData',row)
        router.push('/home/classDetails');
       
      },
      fanhui(){
            context.emit("fanhui",3)
          },
    }
    return {
      ...toRefs(state),
      ...methods,
    };
    }
}
</script>
<style lang="scss" scoped>
    :deep div::-webkit-scrollbar {
        width: 5px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
    }
    /*定义滚动条轨道 内阴影+圆角*/
    :deep div::-webkit-scrollbar-track {
        box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
        border-radius: 10px; /*滚动条的背景区域的圆角*/
        background-color: #fff; /*滚动条的背景颜色*/
    }
    /*定义滑块 内阴影+圆角*/
    :deep div::-webkit-scrollbar-thumb {
        box-shadow: 0px 1px 3px #76C8F6 inset; /*滚动条的内阴影*/
        border-radius: 10px; /*滚动条的圆角*/
        background-color: #76C8F6; /*滚动条的背景颜色*/
    }
</style>