<template>
    <!-- 当前任务 -->
    <div style="width:100%;height:600px; display: flex;justify-content: space-between;">
        <div style="width:73%;height:600px;background:#fff">
            <p style="font-weight:600;padding:10px;">待完成任务</p>
            <el-table :data="taskData" stripe style="width: 100%;height:500px" max-height="500px">
                <el-table-column type="index" label="序号" width="80" align="center"/>
                <el-table-column prop="courseName" label="课程名称" align="center"/>
                <el-table-column prop="startTime" label="授课时间" width="180" align="center"/>
                <el-table-column prop="className" label="班级" align="center"/>
                <el-table-column label="操作" align="center">
                    <template #default="scope">
                        <div style="display: flex;justify-content:space-around;" v-if="scope.row.taskStatus == 2">
                            <el-button type='text' text='primary'  @click="gotoclass(scope.row)">去授课</el-button>
                            <el-button type='text' text='primary' @click="teachingcompletion(scope.row)">授课完成</el-button>
                        </div>
                        <div style="display: flex;justify-content:space-around;" v-if="scope.row.taskStatus == 1">
                            <el-button :disabled="scope.row.taskStatus == 1" type='text' text='primary'>去授课</el-button>
                        </div>
                        <div style="display: flex;justify-content:space-around;" v-if="scope.row.taskStatus == 3">
                            <el-button type='text' text='primary'>已授课</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div style="display: flex;justify-content: center;width: 100%;height: 40px;align-items: center;">
                <el-pagination small v-model:current-page="taskForm.pageIndex" layout="prev, pager, next" :total="taskData.length" @current-change="currentChange1"/>
            </div>
        </div>
        <div style="width:25%;height:600px;background:#fff">
            <p style="font-weight:600;padding:10px;">
                <span>消息列表</span>
                <span style="float:right;font-size:12px;color:#0079FF;font-weight:0" @click="markall">全部已读</span>
            </p>
            <div style="height:500px;max-height:500px;overflow-y:auto;border-bottom:1px solid #dcdfe6">
                <el-badge class="item" style="margin:10px;min-width:90%;" v-for="item in messageInfoData"
                    :is-dot="item.notificationStatus == 1?true:false" >
                    <el-card class="box-card">
                        <!-- <span style="display:block;float:right;color:red;width:"></span> -->
                        <p style="width:95%;font-size:14px;">
                            {{ item.content }}
                        </p>

                        <p style="text-align:end;font-weight: 400;color: #76C8F6;font-size:12px;cursor: pointer;"
                            v-if="item.notificationStatus == 1?true:false" @click="markread(item)">标记已读</p>
                    </el-card>
                </el-badge>
                <div style="width:95%;font-size:14px;text-align:center;margin-top:50px;" v-if="messageInfoData.length == 0">
                    暂无消息</div>
            </div>

            <div style="display: flex;justify-content: center;width: 100%;height: 40px;align-items: center;">
                <el-pagination small v-model:current-page="currentPage4" layout="prev, pager, next" :total="20" @current-change="currentChange" />
            </div>

        </div>
    </div>
</template>
<script>
import { reactive, toRefs, onMounted, onUnmounted } from "vue";
import { f_get_teacherTaskPageList, f_get_studentTaskPageList, f_update_teacherTaskStatus, f_get_userMessage, f_update_userMsgStatus } from '@/js/task'
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { setStorage, getStorage, Newdata } from "@/js/common";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
    setup() {
        const store = useStore();
        const router = useRouter();
        const state = reactive({
            taskForm: {
                teacherId: getStorage('userId'),
                studentId: getStorage('userId'),
                pageIndex: 1,
                pageSize: 10
            },
            taskData: [],
            currentPage4:1,
            messageInfoData: [],
            hidddendot: true,
            isread: true
        });
        onMounted(() => {
            methods.currentTask();//当前任务
            methods.messageInfo()
        })
        const methods = {
            //当前任务
            currentTask() {
                const userType = getStorage("StudentOrTeacher");
                if (userType == 1) {
                    let datas = {
                        teacherId: getStorage('userId'),
                        studentId: getStorage('userId'),
                        pageIndex: state.taskForm.pageIndex,
                        pageSize: 10,
                    }
                    f_get_teacherTaskPageList(datas).then(res => {
                        const { code, data } = res.data
                        console.log(data.list)
                        if (code == 1) {
                            state.taskData = data.list
                        }
                    })
                } else {
                    let datas = {
                        teacherId: getStorage('userId'),
                        studentId: getStorage('userId'),
                        pageIndex: state.taskForm.pageIndex,
                        pageSize: 10,
                    }
                    f_get_studentTaskPageList(datas).then(res => {
                        const { code, data } = res.data
                        if (code == 1) {
                            state.taskData = data.list
                        }
                    })
                }
            },
            //授课完成
            teachingcompletion(item) {
                ElMessageBox.confirm(
                    '确认要完成当前授课吗',
                    '删除',
                    {
                        confirmButtonText: '确认',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(() => {
                        f_update_teacherTaskStatus(item.id).then(res => {
                            console.log(res.data)
                            if (res.data.code == 1) {
                                ElMessage({
                                    type: 'success',
                                    message: '成功',
                                })
                                methods.currentTask()
                            }

                        })
                    })
                    .catch(() => {
                        ElMessage({
                            type: 'info',
                            message: '取消删除',
                        })
                    })
            },
            //去授课
            gotoclass(item) {
                console.log(item)
                setStorage('courseId',item.courseId);
                router.push('/home/courseDetailsCopy')
            },
            //获取所有消息
            messageInfo() {
                let params = {
                    userId: getStorage('userId'),
                    pageIndex: state.currentPage4,
                    pageSize: 10,
                }
                f_get_userMessage(params).then(res => {
                    if (res.data.code == 1) {
                        // res.data.data.list.forEach(element => {
                        //     console.log(element, 'ele')
                        //     element.hidddendot = true
                        //     element.isread = true
                        // });
                        state.messageInfoData = res.data.data.list
                    }
                })
            },
            currentChange(val){
                state.currentPage4 = val;
                methods.messageInfo();
            },
            currentChange1(val){
                state.taskForm.pageIndex = val;
                methods.currentTask();
            },
            //标记已读
            markread(item) {
                let params = {
                    userId: getStorage('userId'),
                    notificationId: item.id
                }
                f_update_userMsgStatus(params).then(res => {
                    console.log(res.data)
                    if (res.data.code == 1) {
                        // item.isread = false
                        // item.hidddendot = false
                    }

                    methods.messageInfo()
                })
            },
            //全部已读
            markall() {
                let params = {
                    userId: getStorage('userId'),
                    notificationId: ''
                }
                f_update_userMsgStatus(params).then(res => {
                    console.log(res.data)
                    if (res.data.code == 1) {
                        // state.messageInfoData.forEach((item) => {
                        //     console.log(item, 'item')
                        //     item.hidddendot = false
                        //     item.isread = false
                        // })
                    }
                    methods.messageInfo()
                })
            }
        }

        return {
            ...toRefs(state),
            ...methods,
        };
    }
}
</script>