<template>
    <div>
        <div style="width:100%;height:600px;background:#fff;">
            <el-input v-model="systemExperienceName" placeholder="系统实训名称" style="width:200px;margin:10px;" size="mini"></el-input>
            <el-select v-model="statusValue" class="m-2" placeholder="选择状态" size="mini">
            <el-option
            v-for="item in statusArray"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            />
        </el-select>
            <el-button size="mini" type="primary" @click="searchClick" style="background-color: #76C8F6;border-color:#76C8F6;margin-left:10px">搜索</el-button>
          <el-button type="primary" round size="mini" style="background-color: #76C8F6;border: none;float:right;margin-top:30px;margin-right:20px;" @click="addSys">添加系统实训</el-button>
          <el-table :data="tableData" stripe style="width: 100%;height:500px" height="480" max-height="484px">
            <el-table-column type="index" width="30" />
            <el-table-column prop="systemExperienceName" label="系统实训名称"  />
            <el-table-column prop="systemLanguage" label="系统名称" />
            <el-table-column prop="realName" label="创建人" />
            <el-table-column prop="trainingCount" label="实训次数" />
            <el-table-column prop="insertTime" label="创建时间" width="180" />
            <el-table-column  label="操作" fixed="right"  width="120">
              <template #default="scope">
                <span style="cursor: pointer;color:#76C8F6" @click="editClick(scope.row)">编辑</span>
                <span style="cursor: pointer;color:red;margin-left:20px" @click="editStatus(scope.row)">{{ scope.row.status == 1?'下架':scope.row.status == 2?'上架':scope.row.status == 3?'上架':'' }}</span>
              </template>
              
            </el-table-column>
          </el-table>
          <div style="display: flex;justify-content: center;width: 100%;height: 40px;align-items: center;">
            <el-pagination
            style="text-align:center"
            :current-page="currentPage1"
            :page-size="pageSize4"
            background
            layout="total, prev, pager, next"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            ></el-pagination>
        </div>  
       </div>
       <el-dialog
        v-model="dialogVisible"
        title="选择系统"
        width="20%"
        :before-close="handleClose">
        
            <p style="margin-top: 10px;">
                <span style="font-size: 14px;padding-left: 10px;">选择平台：</span>
                <el-select v-model="platformValue" class="m-2" placeholder="请选择" style="width: 60%;" size="small">
                <el-option
                v-for="item in platform"
                :key="item.id"
                :label="item.systemName"
                :value="item.id"
                :disabled="item.status == 1?false:true"
                />
            </el-select>
            </p>
           
        <template #footer>
        <div style="text-align: center;" class="dialog-footer">
            <el-button @click="dialogVisible = false" round>取消</el-button>
            <el-button type="primary" style="background-color: #76C8F6;border: none;" @click="quedingClick" round>
            确定
            </el-button>
      </div>
    </template>
  </el-dialog>
    </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted } from "vue";
// import router from "../../router/index.js";
import {f_get_systemExperiencePageList,get_select_trainingSystem,f_update_systemExperienceStatus} from '@/js/teachingSquare'
import { setStorage,getStorage,Newdata} from "@/js/common";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {courseData} from "@/js/caseApi";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
    setup() {
        const store = useStore();
    const router = useRouter();
    const state = reactive({
        dialogVisible:false,
        selectvalue2:'',
        systemExperienceName:null,
        currentPage1:1,
        pageSize4:10,
        statusValue:null,
        statusArray:[
            {
                label:'全部',
                value:0
            },{
                label:'上架',
                value:1
            },{
                label:'下架',
                value:2
            },
        ],
        options:[],
        total:0,
        platformValue:'',
        platform:[],
        tableData:[],
    })
    onMounted(()=>{
        methods.f_get_systemExperiencePageList();
    })
    const methods = {
        // 修改状态
        editStatus(row){
            ElMessageBox.confirm(
    '您确定要修改此数据状态吗？',
    '提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(() => {
        f_update_systemExperienceStatus(row.id).then(res=>{
                if (res.data.code == 1) {
                    ElMessage({
                                type: 'success',
                                message: '成功',
                                })
                                methods.f_get_systemExperiencePageList();
                }else{
                    ElMessage({
                                type: 'error',
                                message: res.data.message,
                                })
                }
            })
     
    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: '已取消',
      })
    })
            
        },
        get_select_trainingSystem(){
            
            get_select_trainingSystem().then(res=>{
                if (res.data.code == 1) {
                    state.platform = res.data.data;
                }
                
            })
        },
        // 系统实训列表数据
        f_get_systemExperiencePageList(){
            let params = {
                teacherId:getStorage('userId'),
                systemExperienceName:state.systemExperienceName,
                status:state.statusValue,
                pageIndex:state.currentPage1,
                pageSize:state.pageSize4
            }
            f_get_systemExperiencePageList(params).then(res=>{
                if (res.data.code == 1) {
                    state.tableData = res.data.data.list;
                    state.total = res.data.data.totalCount.Value;
                }
            })
        },
        searchClick(){
            state.currentPage1 = 1;
            methods.f_get_systemExperiencePageList();
        },
        handleSizeChange(val){
                state.pageSize4 = val;
                methods.f_get_systemExperiencePageList();
            },
            handleCurrentChange(val){
                state.currentPage1 = val;
                methods.f_get_systemExperiencePageList();
            },
        addSys(){
            state.dialogVisible = true;
            methods.get_select_trainingSystem();
        },
        editClick(row){
            setStorage('platformRow',row)
            router.push({path:'/home/addSystemExperience',query:{platformValue:row.trainingSystemId,title:'编辑'}})
        },
        quedingClick(){
            if (state.platformValue) {
                state.platform.forEach(element => {
                    if (state.platformValue == element.id) {
                        setStorage('platformRow',element)
                    }
                });
                router.push({path:'/home/addSystemExperience',query:{platformValue:state.platformValue,title:'添加'}})
            }
            
        },
        handleClose(){
            state.dialogVisible = false;
        },
    }
    return {
      ...toRefs(state),
      ...methods,
    };
    }
}
</script>
<style lang="scss" scoped>
    :deep div::-webkit-scrollbar {
        width: 5px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
    }
    /*定义滚动条轨道 内阴影+圆角*/
    :deep div::-webkit-scrollbar-track {
        box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
        border-radius: 10px; /*滚动条的背景区域的圆角*/
        background-color: #fff; /*滚动条的背景颜色*/
    }
    /*定义滑块 内阴影+圆角*/
    :deep div::-webkit-scrollbar-thumb {
        box-shadow: 0px 1px 3px #76C8F6 inset; /*滚动条的内阴影*/
        border-radius: 10px; /*滚动条的圆角*/
        background-color: #76C8F6; /*滚动条的背景颜色*/
    }
</style>