<template>
  <div>
    <div style="width:100%;height:600px;background:#fff;">
      <el-input v-model="digitalTraining" size="mini" style="margin-top: 10px;margin-left: 10px;width: 200px;"  placeholder="请输入课程实训名称" />
      <el-button type="primary" size="mini" style="margin-left: 10px;background-color: #76C8F6;border: none;" @click="submitClick">搜索</el-button>
      <el-button type="primary" round
        style="background-color: #76C8F6;border: none;float:right;margin-top:10px;margin-right:20px;" size="mini"
        @click="addDigita">添加课程实训</el-button>
      <el-table :data="tableData" stripe style="width: 100%;height:500px" max-height="500px">
        <el-table-column type="index" width="30" align="center" />
        <el-table-column prop="trainingName" label="课程实训名称" align="center" />
        <el-table-column prop="dtKnowledgePointCount" label="知识点数量" align="center" />
        <el-table-column prop="trainingCount" label="实训次数" align="center" />
        <el-table-column prop="realName" label="创建人" align="center" />
        <el-table-column prop="insertTime" label="创建时间" width="180" align="center" />
        <el-table-column label="操作" fixed="right" width="120"  align="center">
          <template #default="scope">
            <span style="cursor: pointer;color:#76C8F6" @click="editClick(scope.row)">编辑</span>
            <span style="cursor: pointer;color:red;margin-left:20px" @click="modifyStatus(scope.row)">{{scope.row.status == 1?'下架':'上架'}}</span>
          </template>

        </el-table-column>
      </el-table>
      <div style="display: flex;justify-content: center;width: 100%;height: 40px;align-items: center;" v-if="pageTotal > 5">
          <el-pagination
            v-model:current-page="currentPage4"
            :page-size="pageSize"
            :small="small"
            layout="prev, pager, next"
            :total="pageTotal"
            @current-change="handleCurrentChange"
          />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
// import router from "../../router/index.js";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { getStorage, setStorage } from "@/js/common";
import { F_Get_digitalTrainingPageList } from "@/js/caseApi";
import { ElMessage, ElMessageBox } from "element-plus";
import { f_update_digitalTrainingStatusss } from '@/js/teachingSquare'
export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      digitalTraining:null,
      tableData: [],
      pageSize:5,
      currentPage4:1,
      pageTotal:0,
    })
    onMounted(() => {
      methods.getDigitalList()
    });
    const methods = {
      addDigita() {
        router.push('/home/addDigitalTraining')
      },
      //获取列表数据
      getDigitalList() {
        let params = {
          teacherId:getStorage('userId'),
          status:0,
          dtName:state.digitalTraining,
          pageIndex:state.currentPage4,
          pageSize:state.pageSize,
        }
        F_Get_digitalTrainingPageList(params).then(res => {
          if (res.data.code == 1) {
            state.tableData = res.data.data.list;
            state.pageTotal = res.data.data.totalCount.Value;
            console.log("🚀 ~ file: systemExperience.vue:74 ~ F_Get_digitalTrainingPageList ~ state.pageTotal:", state.pageTotal)
          }
        })
      },
      handleCurrentChange(val){
        state.currentPage4 = val;
        methods.getDigitalList();
      },
      // 编辑
      editClick(row){
        router.push({path:'/home/addDigitalTraining',query:{rowId:row.id,title:'编辑'}})
      },
      // 上架下架
      modifyStatus(row){
        ElMessageBox.confirm(
    '您确定要修改此数据状态吗？',
    '提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(() => {
      f_update_digitalTrainingStatusss(row.id).then(res=>{
                if (res.data.code == 1) {
                    ElMessage({
                                type: 'success',
                                message: '成功',
                                })
                                methods.getDigitalList();
                }else{
                    ElMessage({
                                type: 'error',
                                message: res.data.message,
                                })
                }
            })
     
    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: '已取消',
      })
    })
      },
      submitClick(){
        state.currentPage4 = 1;
        methods.getDigitalList()
      },
    }
    return {
      ...toRefs(state),
      ...methods,
    };
  }
}
</script>
<style lang="scss" scoped>
:deep div::-webkit-scrollbar {
  width: 5px;
  /*滚动条宽度*/
  height: 10px;
  /*滚动条高度*/
}

/*定义滚动条轨道 内阴影+圆角*/
:deep div::-webkit-scrollbar-track {
  box-shadow: 0px 1px 3px #fff inset;
  /*滚动条的背景区域的内阴影*/
  border-radius: 10px;
  /*滚动条的背景区域的圆角*/
  background-color: #fff;
  /*滚动条的背景颜色*/
}

/*定义滑块 内阴影+圆角*/
:deep div::-webkit-scrollbar-thumb {
  box-shadow: 0px 1px 3px #76C8F6 inset;
  /*滚动条的内阴影*/
  border-radius: 10px;
  /*滚动条的圆角*/
  background-color: #76C8F6;
  /*滚动条的背景颜色*/
}</style>