<template>
    <div>
        <div style="width:100%;height:50px;background:#fff;">
            <div style="float: left;display: inline-flex;margin-left: 10px;margin-top: 10px;">
            <el-input v-model="courseSearch" clearable size="mini" placeholder="请输入课程名称"></el-input>
            <el-button type="primary"  style="background-color: #76C8F6;border: none;margin-left: 10px;" size="mini" @click="searchClick">搜索</el-button>
            </div>
          <el-button type="primary" round style="background-color: #76C8F6;border: none;float:right;margin-top:5px;margin-right:20px;" @click="goUploadCourse()">上传课程</el-button>
        </div> 
        <div style="width:100%;height:500px;margin-top:10px" >
            <el-card class="box-card" style="margin-bottom:10px" v-for="(item,index) in teacherList" :key="index">
                <div style="height:150px;float:left;display:flex;width:300px">
                    <img style="width:100%;object-fit: cover;" :src=item.baseUrlfilePath alt="" srcset="">
                </div>
                <div style="width:20%;height:170px;float:left;margin-left:20px;line-height:30px;">
                    <p style="font-size:18px;font-weight:600">{{ item.courseName }}</p>
                   
                </div>
              
                <div style="width:20%;height:170px;float:right;margin-left:10px;line-height:130px;display:flex;justify-content: center;">
                    <!-- <span style="font-size:14px;color:#76C8F6;cursor: pointer;margin-right:5px;">去授课</span> -->
                    <!-- <span style="font-size:14px;color:#76C8F6;cursor: pointer;margin-right:5px;">详情</span> -->
                    <el-button type='text'  @click="editClick(item)">编辑</el-button>
                    <el-button type='text'  @click="grounding(item)" :disabled="item.status == 3">{{item.status == 1?'下架' :item.status == 2?'上架':'上架'}}</el-button>
                </div>
          </el-card>
          <el-pagination
          style="text-align:center"
            :current-page="currentPage1"
            :page-size="pageSize4"
            background
            layout="total, prev, pager, next"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            />
        </div>
    </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted } from "vue";
// import router from "../../router/index.js";
import {f_get_teacherCoursePageList,f_update_courseStatus} from '@/js/teachingSquare'
import { setStorage,getStorage,Newdata} from "@/js/common";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {baseUrl} from '@/js/util.js'
import {courseData} from "@/js/caseApi";
import {ElMessage} from "element-plus";
export default {
    setup() {
        const store = useStore();
        const router = useRouter();
        const state = reactive({
            teacherList:[],
            total:0,
            currentPage1:1,
            pageSize4:5,
            baseUrl:baseUrl,
            courseSearch:'',

        })
        onMounted(() => {
           methods.f_get_teacherCoursePageList();
        });
        const methods = {
            // 上架
            grounding(item){
                f_update_courseStatus(item.id).then(res=>{
                    if (res.data.code == 1) {
                        ElMessage({
                            type: 'success',
                            message: '成功',
                            })
                            methods.f_get_teacherCoursePageList();
                    }else{
                        ElMessage({
                            type: 'error',
                            message: res.data.message,
                            })
                    }
                })
            },
            f_get_teacherCoursePageList(){
                let params = {
                    teacherId:getStorage('userId'),
                    pageIndex:state.currentPage1,
                    pageSize:state.pageSize4,
                    courseName:state.courseSearch

                }
                f_get_teacherCoursePageList(params).then(res=>{
                    if (res.data.code == 1) {
                        state.teacherList = res.data.data.list;
                        state.total = res.data.data.totalCount.Value;
                        state.teacherList.forEach(element => {
                            element.baseUrlfilePath = state.baseUrl+'/'+element.file.filePath;
                        });
                        console.log(state.teacherList);
                    }
                })
            },
            // 搜索
            searchClick(){
                state.currentPage1 = 1;
                methods.f_get_teacherCoursePageList();
            },
            handleSizeChange(val){
                state.pageSize4 = val;
                methods.f_get_teacherCoursePageList();
            },
            handleCurrentChange(val){
                state.currentPage1 = val;
                methods.f_get_teacherCoursePageList();
            },
            goUploadCourse(){
                router.push('/home/UploadCourse')
            },
            editClick(row){
                router.push({
                    name:'UploadCourse',
                    query:{ itemRow: JSON.stringify(row) }
                })
            },
        }
    return {
      ...toRefs(state),
      ...methods,
    };
    }
}
</script>
<style lang="scss" scoped>
    :deep div::-webkit-scrollbar {
        width: 5px; /*滚动条宽度*/
        height: 10px; /*滚动条高度*/
    }
    /*定义滚动条轨道 内阴影+圆角*/
    :deep div::-webkit-scrollbar-track {
        box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
        border-radius: 10px; /*滚动条的背景区域的圆角*/
        background-color: #fff; /*滚动条的背景颜色*/
    }
    /*定义滑块 内阴影+圆角*/
    :deep div::-webkit-scrollbar-thumb {
        box-shadow: 0px 1px 3px #76C8F6 inset; /*滚动条的内阴影*/
        border-radius: 10px; /*滚动条的圆角*/
        background-color: #76C8F6; /*滚动条的背景颜色*/
    }
</style>